import TripService from "../services/entity.trip.service";

// Action to get entity by id
export const readTrip = (entityId, id) => (dispatch) => {
    return TripService.readTrip(entityId, id).then((data) => {
        console.log(data.result.checkout_start_date);
        console.log(data.result.checkout_end_date);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all active trip translations by entity id
export const readAllActiveTripsTranslations = (entityId) => (dispatch) => {
    return TripService.readAllActiveTripsTranslations(entityId).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to count all entity trips
export const countTrips = (entityId) => (dispatch) => {
    return TripService.countTrips(entityId).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all entity trips
export const readTrips = (entityId, params) => (dispatch) => {
    return TripService.readTrips(entityId, params).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the entity trip
export const createTrip = (entityId, trip) => (dispatch) => {
    return TripService.createTrip(entityId, trip).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the entity trip
export const updateTrip = (entityId, id, trip) => (dispatch) => {
    return TripService.updateTrip(entityId, id, trip).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the entity trip
export const deleteTrip = (entityId, id) => (dispatch) => {
    return TripService.deleteTrip(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}
// Action to duplicate the entity trip
export const duplicateTrip = (entityId, id) => (dispatch) => {
    return TripService.duplicateTrip(entityId, id).then((
        data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

