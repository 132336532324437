import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Select, Tabs, Drawer } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import dot from 'dot-object';
import EntityMedia from './entity.media.page';
import { API_CDN_URL } from '../services/config';
import {
    readRootArticleCategories,
    readArticleCategory,
    createArticleCategory,
    updateArticleCategory
} from '../actions/entity.article.category';
import { userIsReadOnlyInEntity } from '../helpers/user';
import { useTranslation } from 'react-i18next';
import SeoFields from './components/seo.component';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 10,
    },
};

function ArticleCategory() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const entities = useSelector(state => state.entity.entities);
    const currentEntity = entities ? entities.find(entity => entity.id === params.entityId) : null;
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [entityArticleCategoryTitle, setArticleCategoryTitle] = useState('');
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyInEntity(currentUser, currentEntity);
    const requiredArticleSupportAllLanguages = currentEntity && currentEntity.requiredArticleSupportAllLanguages;
    const [isRoot, setIsRoot] = useState(false);
    const [addedLanguages, setAddedLanguages] = useState([]);
    const [mediaDrawerVisible, setMediaDrawerVisible] = useState(false);
    const [currentMediaFieldName, setCurrentMediaFieldName] = useState(null);
    const [articleCategoryData, setArticleCategoryData] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);

    const showMediaDrawer = (fieldName) => {
        setCurrentMediaFieldName(fieldName);
        setMediaDrawerVisible(true);
    };
    const onMediaDrawerClose = () => {
        setMediaDrawerVisible(false);
    };

    const onSelectMedia = (media) => {
        setMediaDrawerVisible(false);
        if (currentMediaFieldName && currentEntity) {
            let url = API_CDN_URL(currentEntity) + media.key;
            let newValue = {
                [currentMediaFieldName.join('.')]: url
            };
            newValue = dot.object(newValue);
            form.setFieldsValue(newValue);
        }
    }

    useEffect(() => {
        if (currentEntity) {
            if (currentEntity.requiredArticleSupportAllLanguages) {
                setAddedLanguages(currentEntity.languages.filter(language => {
                    return language.status
                }).map(language => {
                    return {
                        label: language.name,
                        value: language.code
                    }
                }));
            } else {
                if (articleCategoryData && typeof articleCategoryData.languages !== 'undefined' && Array.isArray(articleCategoryData.languages)) {
                    setAddedLanguages(currentEntity.languages.filter(language => {
                        return language.status && articleCategoryData.languages.includes(language.code)
                    }).map(language => {
                        return {
                            label: language.name,
                            value: language.code
                        }
                    }));
                }
            }
        }
    }, [currentEntity, articleCategoryData]);
    const languageOptions = !requiredArticleSupportAllLanguages && currentEntity && currentEntity.languages ? currentEntity.languages.map(language => {
        return {
            label: language.name,
            value: language.code,
            disabled: !language.status
        }
    }) : [];

    const onFinish = (values) => {
        if (userIsReadOnly) {
            message.error(t('entityArticleCategory.message.read-only'));
            return;
        }
        if (typeof params.articleCategoryId !== 'undefined') {
            // Update the existing entity articleCategory
            dispatch(updateArticleCategory(params.entityId, params.articleCategoryId, values)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setArticleCategoryTitle(t('entityArticleCategory.title.edit'));
                    form.setFieldsValue(response.result);
                    setArticleCategoryData(response.result);
                    setIsRoot(response.result.isRoot ? response.result.isRoot : false);
                }
                message.success(t('entityArticleCategory.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new campaign
            dispatch(createArticleCategory(params.entityId, values)).then((data) => {
                message.success(t('entityArticleCategory.message.create-success'));
                if (data.result) {
                    navigate(`/entity/${params.entityId}/article-category/${data.result.id}`);
                } else {
                    navigate(`/entity/${params.entityId}/article-categories`);
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    }

    useEffect(() => {
        if (typeof params.articleCategoryId !== 'undefined') {
            setArticleCategoryTitle(t('entityArticleCategory.title.edit'));
            dispatch(readArticleCategory(params.entityId, params.articleCategoryId)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setArticleCategoryTitle(t('entityArticleCategory.title.edit'));
                    form.setFieldsValue(response.result);
                    setArticleCategoryData(response.result);
                    setIsRoot(response.result.isRoot ? response.result.isRoot : false);
                } else {
                    message.error(t('entityArticleCategory.message.error-reading'));
                    navigate(`/entity/${params.entityId}/article-categories`);
                }
            }).catch(e => {
                message.error(t('entityArticleCategory.message.error-reading'));
                navigate(`/entity/${params.entityId}/article-categories`);
            });
        } else {
            setArticleCategoryTitle(t('entityArticleCategory.title.new'));
        }
    }, [params.articleCategoryId, params.entityId, dispatch, navigate, form]);

    useEffect(() => {
        dispatch(readRootArticleCategories(params.entityId)).then((data) => {
            if (data.result) {
                setCategoryOptions(data.result.filter(category => {
                    if (params.articleCategoryId) {
                        return category.id !== params.articleCategoryId;
                    } else {
                        return true;
                    }
                }).map(category => {
                    return {
                        label: category.slug,
                        value: category.id
                    };
                }));
            }
        }).catch(error => {
            console.log(error);
        });
    }, [params.entityId, params.articleCategoryId, dispatch]);

    const onFinishFailed = (errorInfo) => {
        if (typeof errorInfo.errorFields !== 'undefined') {
            errorInfo.errorFields.forEach(field => {
                message.error(field.errors[0]);
            });
        }
    }

    const selectedLanguages = (options) => {
        if (Array.isArray(options)) {
            const filteredLanguages = languageOptions.filter(language => {
                if (options.includes(language.value)) {
                    return true;
                } else {
                    return false;
                }
            });
            console.log(options);
            console.log(filteredLanguages);
            setAddedLanguages(filteredLanguages);
        } else {
            setAddedLanguages([]);
        }
    }

    let extraButtons = [
        <Button key="cancel" type="primary" danger href={`/entity/${params.entityId}/article-categories`}>{t('entityArticleCategory.actions.cancel')}</Button>
    ];
    if (!userIsReadOnly) {
        extraButtons.push(<Button key="save" type="primary" htmlType="submit">{params.articleCategoryId ? t('entityArticleCategory.actions.update') : t('entityArticleCategory.actions.save')}</Button>);
    }
    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form
                        {...layout}
                        form={form}
                        name="entity-article-category"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            status: true,
                            isRoot: false
                        }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate(`/entity/${params.entityId}/article-categories`)}
                            title={entityArticleCategoryTitle}
                            extra={extraButtons}
                        />
                        <>
                            <Form.Item
                                label={t('entityArticleCategory.field.status.label')}
                                name="status"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label={t('entityArticleCategory.field.isRoot.label')}
                                name="isRoot"
                                valuePropName="checked"
                            >
                                <Switch onChange={(value) => setIsRoot(value)} />
                            </Form.Item>
                            {!isRoot && <Form.Item
                                label={t('entityArticleCategory.field.parent.label')}
                                name="parent"
                                valuePropName="checked"
                            >
                                <Select allowClear options={categoryOptions} />
                            </Form.Item>}
                            <Form.Item
                                label={t('entityArticleCategory.field.slug.label')}
                                required={true}
                            >
                                <Form.Item
                                    name="slug"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('entityArticleCategory.field.slug.required')
                                        },
                                        { pattern: /^[a-z0-9-_]+$/, message: t('entityArticleCategory.field.slug.pattern') }
                                    ]}
                                    noStyle>
                                    <Input placeholder={t('entityArticleCategory.field.slug.placeholder')} />
                                </Form.Item>
                                <div>{t('entityArticleCategory.field.slug.note')}</div>
                            </Form.Item>
                            {!requiredArticleSupportAllLanguages && <Form.Item
                                label={t('entityArticleCategory.field.languages.label')}
                                required={true}
                            >
                                <Form.Item
                                    name="languages"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('entityArticleCategory.field.languages.required')
                                        }
                                    ]}
                                    noStyle>
                                    <Select mode="multiple" allowClear options={languageOptions} onChange={selectedLanguages} />
                                </Form.Item>
                                <div>{t('entityArticleCategory.field.languages.note')}</div>
                            </Form.Item>}
                            <Tabs>
                                {addedLanguages.map((language, index) => {
                                    return <Tabs.TabPane tab={language.label} key={language.value} forceRender>
                                        <Form.Item
                                            label={t('entityArticleCategory.field.name.label')}
                                            name={[language.value, 'name']}
                                            required={true}
                                            rules={
                                                [
                                                    {
                                                        required: true,
                                                        message: t('entityArticleCategory.field.name.required')

                                                    }
                                                ]}
                                        >
                                            <Input placeholder={t('entityArticleCategory.field.name.placeholder')} />
                                        </Form.Item>
                                        <Form.Item
                                            label={t('entityArticleCategory.field.featured.label')}
                                            name={[language.value, 'featured']}
                                            valuePropName="checked"
                                        >
                                            <Switch />
                                        </Form.Item>
                                        <SeoFields
                                            name={[language.value]}
                                            layout={layout}
                                            form={form}
                                            showMediaDrawer={showMediaDrawer}
                                        />
                                    </Tabs.TabPane>
                                })}
                            </Tabs>
                        </>
                    </Form>
                    <Drawer contentWrapperStyle={{ width: '1000px', maxWidth: '100%', minWidth: '50%' }} title={t('mediaDrawer.title')} placement="right" onClose={onMediaDrawerClose} open={mediaDrawerVisible}>
                        <EntityMedia entityId={params.entityId} selectMedia={onSelectMedia} />
                    </Drawer>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default ArticleCategory;