import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { countTrips, readTrips, deleteTrip, duplicateTrip } from '../actions/entity.trip';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { green, gold, red, cyan } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { readEntity } from '../actions/entity';

function Trips(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { Content } = Layout;
    const [searchParams, setSearchParams] = useSearchParams();
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceCount, setDataSourceCount] = useState(0);
    const defaultPerPageState = searchParams.get('perPage') ? parseInt(searchParams.get('perPage')) : 40;
    const [perPage, setPerPage] = useState(defaultPerPageState);
    const defaultPageState = searchParams.get('page') ? parseInt(searchParams.get('page')) : 1;
    const [page, setPage] = useState(defaultPageState);
    const [websiteUrl, setWebsiteUrl] = useState(null);

    useEffect(() => {
        dispatch(readEntity(params.entityId)).then((response) => {
            const url = response.result.protocol + '://' + response.result.host + (response.result.path ? `/${response.result.path}` : '') + '/zh';
            setWebsiteUrl(url);
        });
    }, [dispatch, params.entityId]);

    const duplicateTripAction = (id) => {
        dispatch(duplicateTrip(params.entityId, id)).then((response) => {
            message.success(t('trips.message.duplicate-success'));
            navigate(`/entity/${params.entityId}/trip/${response.result.id}`);
        }).catch(e => {
            console.log(e);
            message.error(t('trips.message.duplicate-failed'));
        });
    }

    const deleteTripAction = (id) => {
        dispatch(deleteTrip(params.entityId, id)).then((response) => {
            message.success(t('entities.message.delete-success'));
            setDataSource(dataSource.filter(item => item.id !== id));
        }).catch(e => {
            console.log(e);
            message.error(t('entities.message.delete-failed'));
        });
    }

    const columns = [
        {
            title: t('trips.columns.id'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('trips.columns.slug'),
            dataIndex: 'slug',
            key: 'slug',
        },
        {
            title: t('trips.columns.status'),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text, record) => {
                if (typeof record.status !== 'undefined' && record.status === true) {
                    return <CheckCircleOutlined style={{ color: green[5] }} />;
                } else {
                    return <CloseCircleOutlined style={{ color: red[5] }} />;
                }
            }
        },
        {
            title: t('trips.columns.confirmed'),
            dataIndex: 'confirmed',
            key: 'confirmed',
            render: (text, record) => {
                if (typeof record.confirmed !== 'undefined' && record.confirmed === true) {
                    return t('trips.columns.confirmed.enabled');
                } else {
                    return t('trips.columns.confirmed.disabled');
                }
            }
        },
        {
            title: t('trips.columns.visibility'),
            dataIndex: 'visibility',
            key: 'visibility',
            render: (text, record) => {
                return t('trips.columns.visibility.' + record.visibility);
            }
        },
        {
            title: t('trips.columns.period'),
            dataIndex: 'period',
            key: 'period',
            render: (text, record) => {
                return moment(record.start_date).format('YYYY-MM-DD') + ' - ' + moment(record.end_date).format('YYYY-MM-DD');
            }
        },
        {
            title: t('trips.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: t('trips.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <Space>
                <Button size="small" type="primary" href={`/entity/${params.entityId}/trip/${record.id}`}>{t('trips.actions.edit')}</Button>
                {(typeof record.status !== 'undefined' && record.status === true) && <Button size="small" type="primary" style={{ backgroundColor: cyan[5] }} href={`${websiteUrl}/trip/${record.slug}`} target='_blank'>{t('trips.actions.viewTrip')}</Button>}
                <Button size="small" type="primary" style={{ backgroundColor: gold[5] }} href={`/entity/${params.entityId}/orders/?trip=${record.id}`}>{t('trips.actions.orders')}</Button>
                <Popconfirm
                    title={t('trips.actions.delete-confirm')}
                    onConfirm={() => deleteTripAction(record.id)}
                    okText={t('trips.actions.delete-ok')}
                    cancelText={t('trips.actions.delete-cancel')}
                >
                    <Button size="small" type="primary" danger>{t('trips.actions.delete')}</Button>
                </Popconfirm>
                <Popconfirm
                    title={t('trips.actions.duplicate-confirm')}
                    onConfirm={() => duplicateTripAction(record.id)}
                    okText={t('trips.actions.duplicate-ok')}
                    cancelText={t('trips.actions.duplicate-cancel')}
                >
                    <Button size="small" type="primary" style={{ backgroundColor: green[5] }} danger>{t('trips.actions.duplicate')}</Button>
                </Popconfirm>
            </Space>

        }
    ];

    useEffect(() => {
        dispatch(countTrips(params.entityId)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSourceCount(response.result);
            } else {
                setDataSourceCount(0);
            }
        }).catch((error) => {
            try {
                setDataSourceCount(0);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
            }
        });
        dispatch(readTrips(params.entityId, { perPage, page })).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, params.entityId, perPage, page, setSearchParams]);

    useEffect(() => {
        setSearchParams({ page, perPage });
    }, [perPage, page, setSearchParams]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate(`/entity/${params.entityId}/dashboard`)}
                        title={t('trips.title')}
                        subTitle={t('trips.subtitle')}
                        extra={[
                            <Button key="create" type="primary" href={`/entity/${params.entityId}/trip`}>{t('trips.actions.new')}</Button>
                        ]}
                    />
                    <Table
                        size="small"
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                            position: ['topRight', 'bottomRight'],
                            total: dataSourceCount,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            showSizeChanger: true,
                            pageSize: perPage,
                            current: page,
                            onChange: (page, pageSize) => {
                                setPage(page);
                                setPerPage(pageSize);
                            }
                        }}
                    />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Trips;