import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Select, Tabs, Drawer } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import dot from 'dot-object';
import EntityMedia from './entity.media.page';
import { API_CDN_URL } from '../services/config';
import {
    readTripType,
    createTripType,
    updateTripType
} from '../actions/entity.trip.type'
import { userIsReadOnlyInEntity } from '../helpers/user';
import { useTranslation } from 'react-i18next';
import SeoFields from './components/seo.component';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 10,
    },
};

function TripType() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const entities = useSelector(state => state.entity.entities);
    const currentEntity = entities ? entities.find(entity => entity.id === params.entityId) : null;
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [tripTypeTitle, setTripTypeTitle] = useState('');
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyInEntity(currentUser, currentEntity);
    const requiredTripSupportAllLanguages = currentEntity && currentEntity.requiredTripSupportAllLanguages;
    const [addedLanguages, setAddedLanguages] = useState([]);
    const [mediaDrawerVisible, setMediaDrawerVisible] = useState(false);
    const [currentMediaFieldName, setCurrentMediaFieldName] = useState(null);
    const [typeData, setTypeData] = useState(null);

    const showMediaDrawer = (fieldName) => {
        setCurrentMediaFieldName(fieldName);
        setMediaDrawerVisible(true);
    };
    const onMediaDrawerClose = () => {
        setMediaDrawerVisible(false);
    };

    const onSelectMedia = (media) => {
        setMediaDrawerVisible(false);
        if (currentMediaFieldName && currentEntity) {
            let url = API_CDN_URL(currentEntity) + media.key;
            let newValue = {
                [currentMediaFieldName.join('.')]: url
            };
            newValue = dot.object(newValue);
            form.setFieldsValue(newValue);
        }
    }

    useEffect(() => {
        if (currentEntity) {
            if (currentEntity.requiredTripSupportAllLanguages) {
                setAddedLanguages(currentEntity.languages.filter(language => {
                    return language.status
                }).map(language => {
                    return {
                        label: language.name,
                        value: language.code
                    }
                }));
            } else {
                if (typeData && typeof typeData.languages !== 'undefined' && Array.isArray(typeData.languages)) {
                    setAddedLanguages(currentEntity.languages.filter(language => {
                        return language.status && typeData.languages.includes(language.code)
                    }).map(language => {
                        return {
                            label: language.name,
                            value: language.code
                        }
                    }));
                }
            }
        }
    }, [currentEntity, typeData]);
    const languageOptions = !requiredTripSupportAllLanguages && currentEntity && currentEntity.languages ? currentEntity.languages.map(language => {
        return {
            label: language.name,
            value: language.code,
            disabled: !language.status
        }
    }) : [];

    const onFinish = (values) => {
        if (userIsReadOnly) {
            message.error(t('tripType.message.read-only'));
            return;
        }
        if (typeof params.typeId !== 'undefined') {
            // Update the existing entity type
            dispatch(updateTripType(params.entityId, params.typeId, values)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setTripTypeTitle(t('tripType.title.edit'));
                    form.setFieldsValue(response.result);
                    setTypeData(response.result);
                }
                message.success(t('tripType.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new campaign
            dispatch(createTripType(params.entityId, values)).then((data) => {
                message.success(t('tripType.message.create-success'));
                if (data.result) {
                    navigate(`/entity/${params.entityId}/trip-type/${data.result.id}`);
                } else {
                    navigate(`/entity/${params.entityId}/trip-types`);
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    }

    useEffect(() => {
        if (typeof params.typeId !== 'undefined') {
            setTripTypeTitle(t('tripType.title.edit'));
            dispatch(readTripType(params.entityId, params.typeId)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setTripTypeTitle(t('tripType.title.edit'));
                    form.setFieldsValue(response.result);
                    setTypeData(response.result);
                } else {
                    message.error(t('tripType.message.error-reading'));
                    navigate(`/entity/${params.entityId}/trip-types`);
                }
            }).catch(e => {
                message.error(t('tripType.message.error-reading'));
                navigate(`/entity/${params.entityId}/trip-types`);
            });
        } else {
            setTripTypeTitle(t('tripType.title.new'));
        }
    }, [params.typeId, params.entityId, dispatch, navigate, form]);

    const onFinishFailed = (errorInfo) => {
        if (typeof errorInfo.errorFields !== 'undefined') {
            errorInfo.errorFields.forEach(field => {
                message.error(field.errors[0]);
            });
        }
    }

    const selectedLanguages = (options) => {
        if (Array.isArray(options)) {
            const filteredLanguages = languageOptions.filter(language => {
                if (options.includes(language.value)) {
                    return true;
                } else {
                    return false;
                }
            });
            console.log(options);
            console.log(filteredLanguages);
            setAddedLanguages(filteredLanguages);
        } else {
            setAddedLanguages([]);
        }
    }

    let extraButtons = [
        <Button key="cancel" type="primary" danger href={`/entity/${params.entityId}/trip-types`}>{t('tripType.actions.cancel')}</Button>
    ];
    if (!userIsReadOnly) {
        extraButtons.push(<Button key="save" type="primary" htmlType="submit">{params.typeId ? t('tripType.actions.update') : t('tripType.actions.save')}</Button>);
    }
    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form
                        {...layout}
                        form={form}
                        name="entity-type"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            status: true
                        }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate(`/entity/${params.entityId}/trip-types`)}
                            title={tripTypeTitle}
                            extra={extraButtons}
                        />
                        <>
                            <Form.Item
                                label={t('tripType.field.status.label')}
                                name="status"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label={t('tripType.field.slug.label')}
                                required={true}
                            >
                                <Form.Item
                                    name="slug"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('tripType.field.slug.required')
                                        },
                                        { pattern: /^[a-z0-9-_]+$/, message: t('tripType.field.slug.pattern') }
                                    ]}
                                    noStyle>
                                    <Input placeholder={t('tripType.field.slug.placeholder')} />
                                </Form.Item>
                                <div>{t('tripType.field.slug.note')}</div>
                            </Form.Item>
                            {!requiredTripSupportAllLanguages && <Form.Item
                                label={t('tripType.field.languages.label')}
                                required={true}
                            >
                                <Form.Item
                                    name="languages"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('tripType.field.languages.required')
                                        }
                                    ]}
                                    noStyle>
                                    <Select mode="multiple" allowClear options={languageOptions} onChange={selectedLanguages} />
                                </Form.Item>
                                <div>{t('tripType.field.languages.note')}</div>
                            </Form.Item>}
                            <Tabs>
                                {addedLanguages.map((language, index) => {
                                    return <Tabs.TabPane tab={language.label} key={language.value} forceRender>
                                        <Form.Item
                                            label={t('tripType.field.name.label')}
                                            name={[language.value, 'name']}
                                            required={true}
                                            rules={
                                                [
                                                    {
                                                        required: true,
                                                        message: t('tripType.field.name.required')

                                                    }
                                                ]}
                                        >
                                            <Input placeholder={t('tripType.field.name.placeholder')} />
                                        </Form.Item>
                                        <SeoFields
                                            name={[language.value]}
                                            layout={layout}
                                            form={form}
                                            showMediaDrawer={showMediaDrawer}
                                        />
                                    </Tabs.TabPane>
                                })}
                            </Tabs>
                        </>
                    </Form>
                    <Drawer contentWrapperStyle={{ width: '1000px', maxWidth: '100%', minWidth: '50%' }} title={t('mediaDrawer.title')} placement="right" onClose={onMediaDrawerClose} open={mediaDrawerVisible}>
                        <EntityMedia entityId={params.entityId} selectMedia={onSelectMedia} />
                    </Drawer>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default TripType;