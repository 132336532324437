import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Select, Tabs, Drawer, Space, Col, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import dot from 'dot-object';
import MediaSelect from './components/mediaSelect.field';
import EntityMedia from './entity.media.page';
import { API_CDN_URL } from '../services/config';
import { green } from '@ant-design/colors';
import {
    readEntityPage,
    createEntityPage,
    updateEntityPage
} from '../actions/entity.page'
import { readEntity } from '../actions/entity';
import { userIsReadOnlyInEntity } from '../helpers/user';
import { useTranslation } from 'react-i18next';
import SeoFields from './components/seo.component';
import Editor from './components/editor.field';
import { readAllActiveTripCountriesTranslations } from '../actions/entity.trip.country';
import { readAllActiveTripsTranslations } from '../actions/entity.trip';
import LandingPageComponents from './components/landing.page.component';
import AboutPageComponents from './components/about.page.component';


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 10,
    },
};

function EntityPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const entities = useSelector(state => state.entity.entities);
    const currentEntity = entities ? entities.find(entity => entity.id === params.entityId) : null;
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [entityPageTitle, setEntityPageTitle] = useState('');
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyInEntity(currentUser, currentEntity);
    const requiredPageSupportAllLanguages = currentEntity && currentEntity.requiredPageSupportAllLanguages;
    const [addedLanguages, setAddedLanguages] = useState([]);
    const [mediaDrawerVisible, setMediaDrawerVisible] = useState(false);
    const [currentMediaFieldName, setCurrentMediaFieldName] = useState(null);
    const [pageData, setPageData] = useState(null);
    const [tripCountryOptions, setTripCountryOptions] = useState([]);
    const [tripOptions, setTripOptions] = useState([]);
    const [websiteUrl, setWebsiteUrl] = useState(null);

    useEffect(() => {
        dispatch(readEntity(params.entityId)).then((response) => {
            const url = response.result.protocol + '://' + response.result.host + (response.result.path ? `/${response.result.path}` : '') + '/zh';
            setWebsiteUrl(url);
        });
    }, [dispatch, params.entityId]);

    const showMediaDrawer = (fieldName) => {
        setCurrentMediaFieldName(fieldName);
        setMediaDrawerVisible(true);
    };
    const onMediaDrawerClose = () => {
        setMediaDrawerVisible(false);
    };

    const onSelectMedia = (media) => {
        setMediaDrawerVisible(false);
        console.log(currentMediaFieldName);
        if (currentMediaFieldName && currentEntity) {
            let url = API_CDN_URL(currentEntity) + media.key;
            let newValue = {
                [currentMediaFieldName.join('.')]: url
            };
            let formValues = form.getFieldsValue();
            // Use dot to update partial values
            newValue = dot.dot(newValue);
            formValues = dot.dot(formValues);
            newValue = { ...formValues, ...newValue };
            newValue = dot.object(newValue);
            form.setFieldsValue(newValue);
        }
    }

    useEffect(() => {
        if (currentEntity) {
            if (currentEntity.requiredPageSupportAllLanguages) {
                setAddedLanguages(currentEntity.languages.filter(language => {
                    return language.status
                }).map(language => {
                    return {
                        label: language.name,
                        value: language.code
                    }
                }));
            } else {
                if (pageData && typeof pageData.languages !== 'undefined' && Array.isArray(pageData.languages)) {
                    setAddedLanguages(currentEntity.languages.filter(language => {
                        return language.status && pageData.languages.includes(language.code)
                    }).map(language => {
                        return {
                            label: language.name,
                            value: language.code
                        }
                    }));
                }
            }
        }
    }, [currentEntity, pageData]);
    const languageOptions = !requiredPageSupportAllLanguages && currentEntity && currentEntity.languages ? currentEntity.languages.map(language => {
        return {
            label: language.name,
            value: language.code,
            disabled: !language.status
        }
    }) : [];

    const onFinish = (values) => {
        if (userIsReadOnly) {
            message.error(t('entityPage.message.read-only'));
            return;
        }
        if (typeof params.pageId !== 'undefined') {
            // Update the existing entity page
            dispatch(updateEntityPage(params.entityId, params.pageId, values)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setEntityPageTitle(t('entityPage.title.edit'));
                    form.setFieldsValue(response.result);
                    setPageData(response.result);
                }
                message.success(t('entityPage.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new campaign
            dispatch(createEntityPage(params.entityId, values)).then((data) => {
                message.success(t('entityPage.message.create-success'));
                if (data.result) {
                    navigate(`/entity/${params.entityId}/page/${data.result.id}`);
                } else {
                    navigate(`/entity/${params.entityId}/pages`);
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    }

    useEffect(() => {
        if (typeof params.pageId !== 'undefined') {
            setEntityPageTitle(t('entityPage.title.edit'));
            // Fetch the trip
            dispatch(readAllActiveTripsTranslations(params.entityId)).then((response) => {
                if (typeof response.result !== 'undefined') {
                    setTripOptions(response.result.map(tripTranslation => {
                        return {
                            label: `${tripTranslation.name} - ${tripTranslation.parent.slug}`,
                            value: tripTranslation.id,
                            language: tripTranslation.language
                        }
                    }));
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            });
            // Fetch the trip countries
            dispatch(readAllActiveTripCountriesTranslations(params.entityId)).then((response) => {
                if (typeof response.result !== 'undefined') {
                    setTripCountryOptions(response.result.map(countryTranslation => {
                        return {
                            label: countryTranslation.name,
                            value: countryTranslation.id,
                            language: countryTranslation.language
                        }
                    }));
                }
            }).catch(error => {
                console.log(error);
                message.error(error.message);
            });
            dispatch(readEntityPage(params.entityId, params.pageId)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setEntityPageTitle(t('entityPage.title.edit'));
                    console.log(response.result);
                    form.setFieldsValue(response.result);
                    setPageData(response.result);
                } else {
                    message.error(t('entityPage.message.error-reading'));
                    navigate(`/entity/${params.entityId}/pages`);
                }
            }).catch(e => {
                message.error(t('entityPage.message.error-reading'));
                navigate(`/entity/${params.entityId}/pages`);
            });
        } else {
            setEntityPageTitle(t('entityPage.title.new'));
        }
    }, [params.pageId, params.entityId, dispatch, navigate, form]);

    const onFinishFailed = (errorInfo) => {
        if (typeof errorInfo.errorFields !== 'undefined') {
            errorInfo.errorFields.forEach(field => {
                message.error(field.errors[0]);
            });
        }
    }

    const selectedLanguages = (options) => {
        if (Array.isArray(options)) {
            const filteredLanguages = languageOptions.filter(language => {
                if (options.includes(language.value)) {
                    return true;
                } else {
                    return false;
                }
            });
            console.log(options);
            console.log(filteredLanguages);
            setAddedLanguages(filteredLanguages);
        } else {
            setAddedLanguages([]);
        }
    }

    let extraButtons = [
        <Button key="cancel" type="primary" danger href={`/entity/${params.entityId}/pages`}>{t('entityPage.actions.cancel')}</Button>
    ];
    if (websiteUrl && params.pageId && pageData && pageData.slug) {
        extraButtons.push(<Button key="preview" style={{ backgroundColor: green[5] }} type="primary" href={websiteUrl + '/' + pageData.slug} target="_blank">{t('entityPage.actions.preview')}</Button>);
    }
    if (!userIsReadOnly) {
        extraButtons.push(<Button key="save" type="primary" htmlType="submit">{params.pageId ? t('entityPage.actions.update') : t('entityPage.actions.save')}</Button>);
    }
    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form
                        {...layout}
                        form={form}
                        name="entity-page"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            status: true
                        }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate(`/entity/${params.entityId}/pages`)}
                            title={entityPageTitle}
                            extra={extraButtons}
                        />
                        <>
                            <Form.Item
                                label={t('entityPage.field.status.label')}
                                name="status"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label={t('entityPage.field.slug.label')}
                                required={true}
                            >
                                <Form.Item
                                    name="slug"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('entityPage.field.slug.required')
                                        },
                                        { pattern: /^[a-z0-9-_]+$/, message: t('entityPage.field.slug.pattern') }
                                    ]}
                                    noStyle>
                                    <Input placeholder={t('entityPage.field.slug.placeholder')} />
                                </Form.Item>
                                <div>{t('entityPage.field.slug.note')}</div>
                            </Form.Item>
                            {!requiredPageSupportAllLanguages && <Form.Item
                                label={t('entityPage.field.languages.label')}
                                required={true}
                            >
                                <Form.Item
                                    name="languages"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('entityPage.field.languages.required')
                                        }
                                    ]}
                                    noStyle>
                                    <Select mode="multiple" allowClear options={languageOptions} onChange={selectedLanguages} />
                                </Form.Item>
                                <div>{t('entityPage.field.languages.note')}</div>
                            </Form.Item>}
                            <Tabs>
                                {addedLanguages.map((language, index) => {
                                    return <Tabs.TabPane tab={language.label} key={language.value} forceRender>
                                        <Form.Item
                                            label={t('entityPage.field.name.label')}
                                            name={[language.value, 'name']}
                                            required={true}
                                            rules={
                                                [
                                                    {
                                                        required: true,
                                                        message: t('entityPage.field.name.required')

                                                    }
                                                ]}
                                        >
                                            <Input placeholder={t('entityPage.field.name.placeholder')} />
                                        </Form.Item>
                                        <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                            wrapperCol: 16,
                                            label: t('entityPage.field.banner.label')
                                        }} inner={{
                                            name: [language.value, 'banner']
                                        }} />
                                        {/* <Form.Item
                                            labelCol={{ span: 0 }}
                                            wrapperCol={{ span: 24 }}
                                        >
                                            <h1>{t('entityPage.field.content.label')}</h1>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                                                <div>{t('entityPage.field.content.instruction')}</div>
                                                <div><Button key="open-editor" type="primary" onClick={() => initializeEditor([language.value, 'content'])}>{t('entityPage.actions.open-editor')}</Button></div>
                                            </div>
                                            <Form.Item
                                                noStyle
                                                name={[language.value, 'content']}>
                                                <Input.TextArea autoSize={{ minRows: 10, maxRows: 20 }} />
                                            </Form.Item>
                                        </Form.Item> */}
                                        <Editor
                                            label={t('entityPage.field.content.label')}
                                            language={language.value}
                                            name={[language.value, 'content']}
                                            showMediaDrawer={showMediaDrawer}
                                            form={form}
                                        />
                                        {pageData && pageData.slug === 'about' && <AboutPageComponents language={language} form={form} showMediaDrawer={showMediaDrawer} t={t} />}
                                        {pageData && pageData.slug === 'landing' && <LandingPageComponents tripOptions={tripOptions} tripCountryOptions={tripCountryOptions} language={language} form={form} showMediaDrawer={showMediaDrawer} t={t} />
                                        }
                                        <SeoFields
                                            name={[language.value]}
                                            layout={layout}
                                            form={form}
                                            showMediaDrawer={showMediaDrawer}
                                        />
                                    </Tabs.TabPane>
                                })}
                            </Tabs>
                        </>
                    </Form>
                    <Drawer
                        contentWrapperStyle={{ width: '1000px', maxWidth: '100%', minWidth: '50%' }}
                        zIndex={1000}
                        title={t('mediaDrawer.title')}
                        placement="right"
                        destroyOnClose={false}
                        onClose={onMediaDrawerClose}
                        open={mediaDrawerVisible}>
                        <EntityMedia entityId={params.entityId} selectMedia={onSelectMedia} />
                    </Drawer>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default EntityPage;