import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Input, message, Button, Table, Space, Upload, Form, Col, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { CopyOutlined, InboxOutlined, RedoOutlined } from '@ant-design/icons';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { readUserMedia, countUserMedia } from '../actions/user-media';
import { API_CDN_URL, API_USER_MEDIA_URL } from '../services/config';
import authHeader from '../services/auth-header';
import moment from 'moment';
import { userIsReadOnlyInEntity } from '../helpers/user';
import { useTranslation } from 'react-i18next';
import { getShortToken } from '../actions/auth';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

function EntityUserMedia(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { Content } = Layout;
    const [dataSourceCount, setDataSourceCount] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const params = useParams();
    const currentEntityId = params.entityId ? params.entityId : props.entityId;
    const userMediaUploadAction = API_USER_MEDIA_URL(currentEntityId) + 'upload';
    const entities = useSelector(state => state.entity.entities);
    const currentEntity = entities ? entities.find(entity => entity.id === currentEntityId) : null;
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyInEntity(currentUser, currentEntity);
    const [processedUploadedFiles, setProcessedUploadedFiles] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultPerPageState = props.entityId ? 40 : (searchParams.get('perPage') || 40);
    const [perPage, setPerPage] = useState(defaultPerPageState);
    const defaultPageState = props.entityId ? 1 : (searchParams.get('page') || 1);
    const [page, setPage] = useState(defaultPageState);
    const [shortToken, setShortToken] = useState(null);
    // SearchParams
    const [id, setId] = useState(searchParams.get('fileId') ? searchParams.get('id') : null);
    const [user, setUser] = useState(searchParams.get('userId') ? searchParams.get('user') : null);
    const [originalname, setOriginalname] = useState(searchParams.get('originalname') ? searchParams.get('originalname') : null);
    const [path, setPath] = useState(searchParams.get('path') ? searchParams.get('path') : null);

    const onFinish = (values) => {
        if (typeof values.id !== 'undefined') setId(values.id);
        else setId(null);
        if (typeof values.user !== 'undefined') setUser(values.user);
        else setUser(null);
        if (typeof values.originalname !== 'undefined') setOriginalname(values.originalname);
        else setOriginalname(null);
        if (typeof values.path !== 'undefined') setPath(values.path);
        else setPath(null);
    };
    const clearFormSearch = () => {
        form.resetFields();
        setId(null);
        setUser(null);
        setOriginalname(null);
        setPath(null);
    }

    const userMediaUploadChange = (event) => {
        if (Array.isArray(event.fileList)) {
            event.fileList.forEach(uploadedFile => {
                console.log(uploadedFile);
                if (uploadedFile.status === 'error' || uploadedFile.status === 'done') {
                    if (processedUploadedFiles.indexOf(uploadedFile.name) === -1) {
                        setProcessedUploadedFiles([...processedUploadedFiles, uploadedFile.name]);
                        if (uploadedFile.status === 'done') {
                            message.success(uploadedFile.response.message);
                        } else {
                            message.error(uploadedFile.response.message);
                        }
                    }
                }
            });
        }
    }


    const columns = [
        {
            title: t('user-media.columns.id'),
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: t('user-media.columns.user'),
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                return record.user ? <Link to={`/entity/${params.entityId}/user/${record.user._id}`}>{record.user.lastName}, {record.user.firstName}</Link> : 'N/A';
            }
        },
        {
            title: t('user-media.columns.fromAdmin'),
            dataIndex: 'fromAdmin',
            key: 'fromAdmin',
            render: (text, record) => {
                if (typeof record.fromAdmin !== 'undefined' && record.fromAdmin === true) {
                    return t('user-media.columns.yes');
                } else {
                    return t('user-media.columns.no');

                }
            }
        },
        {
            title: t('user-media.columns.originalname'),
            dataIndex: 'meta.originalname',
            key: 'meta.originalname',
            render: (text, record) => {
                return record.meta ? record.meta.originalname : 'N/A';
            }
        },
        {
            title: t('user-media.columns.path'),
            dataIndex: 'path',
            key: 'path',
            render: (text, record) => {
                return record.id + '/' + record.path
            }
        },
        {
            title: t('user-media.columns.link'),
            key: 'link',
            render: (text, record) => {
                if (currentEntity) {
                    let link = API_USER_MEDIA_URL(record.entity) + record.id + '?token=' + (shortToken ? shortToken : '');
                    return <a href={link} rel="noreferrer" target="_blank">Open</a>;
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: t('user-media.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => {
                return moment(record['createdAt']).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            title: t('user-media.columns.actions'),
            key: 'action',
            render: (text, record) => {
                return <Space>
                    {props.entityId && <Button size="small" type="primary" onClick={() => props.selectSelectMedia ? props.selectUserMedia(record) : null}>{t('user-media.actions.select')}</Button>}
                    <Button size="small" type="primary" onClick={() => copyUserMediaUrl(record)}><CopyOutlined /> {t('user-media.actions.copy')}</Button>
                    {/* <Button size="small" type="primary" onClick={() => copyUserMediaId(record)}><CopyOutlined /> {t('user-media.actions.copy-id')}</Button> */}
                </Space>
            }
        }
    ];

    const copyUserMediaId = (record) => {
        let url = record.id;
        navigator.clipboard.writeText(url);
        message.success(t('user-media.message.copied'));
    }

    const copyUserMediaUrl = (record) => {
        let url = record.id + '/' + record.path;
        navigator.clipboard.writeText(url);
        message.success(t('user-media.message.copied'));
    }

    const reloadUserMedia = useCallback(() => {
        dispatch(getShortToken()).then((data) => {
            if (data && data.shortToken) {
                setShortToken(data.shortToken);
            }
        }).catch(e => {
            console.log(e.message);
        });
        let noNavigationParams = {};
        if (id) noNavigationParams.id = id;
        if (user) noNavigationParams.user = user;
        if (originalname) noNavigationParams.originalname = originalname;
        if (path) noNavigationParams.path = path;
        dispatch(countUserMedia(currentEntityId, noNavigationParams)).then(function (response) {
            if (typeof response.result !== 'undefined') {
                setDataSourceCount(response.result);
            } else {
                message.error('Error while fetching media');
            }
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
        dispatch(readUserMedia(currentEntityId, Object.assign({ page, perPage }, noNavigationParams))).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                message.error('Error while fetching media');
            }
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, currentEntityId, page, perPage, id, user, originalname, path]);

    useEffect(() => {
        console.log('123');
        let params = { page, perPage };
        if (id) params.id = id;
        if (user) params.user = user;
        if (originalname) params.originalname = originalname;
        if (path) params.path = path;
        if (typeof props.entityId === 'undefined') setSearchParams(params);
        reloadUserMedia();
    }, [perPage, page, id, path, user, originalname, currentEntityId]);

    const headerBack = () => navigate('/');
    const innerUserMediaLibrary = <><PageHeader
        className="site-page-header"
        onBack={props.entityId ? null : headerBack}
        title={t('user-media.title')}
        subTitle={t('user-media.subtitle')}
        extra={[
            <Button key="refresh" type="primary" onClick={() => reloadUserMedia()}>
                <RedoOutlined /> {t('user-media.actions.refresh')}</Button>
        ]}
    />
        {!userIsReadOnly && <div style={{ marginBottom: 20 }}>
            <Upload.Dragger
                name="files"
                headers={authHeader()}
                action={userMediaUploadAction}
                onChange={userMediaUploadChange} multiple={true}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('user-media.upload.action-reminder')}</p>
                <p className="ant-upload-hint">{t('user-media.upload.support-reminder')}</p>
            </Upload.Dragger>
        </div>}
        {!userIsReadOnly && <h4>{t('user-media.message.refresh-reminder')}</h4>}
        <Form
            {...layout}
            form={form}
            name="entity-article-category"
            onFinish={onFinish}
            style={{ marginTop: 8, backgroundColor: '#eeeeee', padding: 16 }}
        >
            <h3>Find User Media</h3>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Form.Item name="id" label={t('user-media.columns.id')}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="user" label={t('user-media.columns.user')}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="originalname" label={t('user-media.columns.originalname')}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="path" label={t('user-media.columns.path')}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Space>
                <Button type="primary" htmlType="submit">{t('user-media.actions.search')}</Button>
                <Button type="primary" danger onClick={clearFormSearch}>{t('user-media.actions.clear')}</Button>
            </Space>
        </Form>
        <Table
            size='small'
            rowKey={record => record.id}
            dataSource={dataSource}
            columns={columns}
            pagination={{
                position: ['topRight', 'bottomRight'],
                total: dataSourceCount,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                pageSize: perPage,
                current: page,
                onChange: (page, pageSize) => {
                    setPage(page);
                    setPerPage(pageSize);
                }
            }}
        /></>;

    if (props.entityId) {
        // Means called in drawer
        return innerUserMediaLibrary;
    } else {
        return <>
            <MemberSidebar />
            <Layout className="site-layout">
                <Content style={{ margin: '0 16px' }}>
                    <div className="site-layout-background">
                        {innerUserMediaLibrary}
                    </div>
                </Content>
                <MemberFooter />
            </Layout>
        </>;
    }
}

export default EntityUserMedia;