import authHeader from './auth-header';
import axios from "axios";
import { API_USER_MEDIA_URL } from "./config";

// API to read all user-media by entity Id
const readUserMedia = (entityId, params) => {
    let query = '?1=1';
    if (typeof params !== 'undefined') {
        for (let key in params) {
            query += `&${key}=${params[key]}`;
        }
    }
    return axios.get(API_USER_MEDIA_URL(entityId) + query, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to count all user-media by entity Id
const countUserMedia = (entityId, params) => {
    let query = '?1=1';
    if (typeof params !== 'undefined') {
        for (let key in params) {
            query += `&${key}=${params[key]}`;
        }
    }
    return axios.get(API_USER_MEDIA_URL(entityId) + 'count' + query, {
        headers: authHeader()
    }).then(res => res.data);
}

const userMedia = {
    readUserMedia,
    countUserMedia
}

export default userMedia;