import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_TRIP_URL } from "./config";
import qs from 'qs';

// API to get entity trip by id
const readTrip = (entityId, id) => {
    return axios.get(API_ENTITY_TRIP_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}
// API to count all entity trips
const countTrips = (entityId) => {
    return axios.get(API_ENTITY_TRIP_URL(entityId) + 'count', {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all entity trips
const readTrips = (entityId, params) => {
    return axios.get(API_ENTITY_TRIP_URL(entityId) + '?' + qs.stringify(params), {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all active trip translations by entity id
const readAllActiveTripsTranslations = (entityId) => {
    return axios.get(API_ENTITY_TRIP_URL(entityId) + 'translations', {
        headers: authHeader()
    }).then(res => res.data);
}



// API to create entity trip
const createTrip = (entityId, trip) => {
    return axios
        .post(API_ENTITY_TRIP_URL(entityId), trip, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update entity trip
const updateTrip = (entityId, id, trip) => {
    return axios
        .put(API_ENTITY_TRIP_URL(entityId) + id, trip, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete entity trip
const deleteTrip = (entityId, id) => {
    return axios
        .delete(API_ENTITY_TRIP_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to duplicate entity trip
const duplicateTrip = (entityId, id) => {
    return axios
        .post(API_ENTITY_TRIP_URL(entityId) + id + '/duplicate', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const trips = {
    readAllActiveTripsTranslations,
    createTrip,
    readTrip,
    readTrips,
    countTrips,
    updateTrip,
    deleteTrip,
    duplicateTrip
};

export default trips;